<template>
	<div>
		<div :hidden='hiddenLoader' id="loader" class="text-center">
			<b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
		</div>

		<div>
			<div :hidden="hidden">
				<div class="mb-1 d-flex justify-content-end">
					<b-button
						v-if="$hasFonction(user, 'Ajouter groupes utilisateurs')"
						v-b-modal.modal-ajouter-groupe-utilisateur
						variant="primary"
						size="md"
					>
					Ajouter un groupe
					</b-button>
				</div>
				<b-card
					no-body
					title=""
					class="mt-1"
				>	
					<div class="my-2 mx-2 row">
						<!-- search start -->
						<div class="col-lg-8 mb-2">
							<b-form-input
								id="filter-input"
								v-model="filter"
								type="search"
								placeholder="Recherche"
								class="col4 mr-5 pr-5"
								style="width:40%"
							>
							</b-form-input>
						</div>
						<!-- search end -->
						<!-- bouton export start -->

						<div class="col-lg-4">

							<b-button
							variant="outline-primary"
							size="sm"
							@click="ExportFomatExcel"
							class="ml-2"
						>
							<b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
							Exporter
						</b-button>
						<!-- bouton export end -->

							<!-- bouton export start -->
							<b-button
							variant="outline-primary"
							size="sm"
							@click="ExportAllToFomatExcel"
							class="ml-2"
						>
							<b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
							Tout Exporter
						</b-button>

						</div>

						<!-- bouton export end -->

					</div>

					<!-- table start -->
					<b-table 
						v-model="ExcelGroupeUtilisateur"
						id="my-table"
						responsive="sm" 
						:fields="headers" 
						:items="groupeUtilisateur"
						:per-page="perPage"
						:current-page="currentPage"
						:filter="filter"
						@filtered="onFiltered"
						show-empty 
						class="border-bottom"
					>
						<!-- statut start -->
						<template #cell(statut)="data">
							<b-badge
								class="text-uppercase"
                :variant="statusVariant(data.item.show)"
							>
								<span v-if="data.item.show === 1 || data.item.show === true ||data.item.show === '1' ">
									actif 
								</span>
								<span v-else-if="data.item.show === 0 || data.item.show === false || data.item.show === '0' ">
									Inactif
								</span>
							</b-badge>
						</template>
						<!-- statut end -->

						<!-- etat start -->
						<template #cell(etat)="data">
							<b-form-checkbox
								v-if="$hasFonction(user, 'Mise a jour privilège')"
								switch
                :disabled="!isDeletable(data.item.libelle)"
                inline
								value=1
								unchecked-value=0
								name="check-button"
								v-model="data.item.show"
								@change="dialogstatut(data.item)"
							>
							</b-form-checkbox>
						</template>
						<!-- etat end -->

						<!-- iconAction start -->
						<template #cell(actions)="data">
							<b-button 
								v-if="$hasFonction(user, 'Modifier libellé groupes utilisateurs')"
								size="sm" 
								variant="flat-primary" 
								class="btn-icon"
                :disabled="!isDeletable(data.item.libelle)"
                @click="editItem(data.item)"
							>
								<feather-icon icon="Edit2Icon" />
							</b-button>

							<b-button 
								v-if="$hasFonction(user, 'Mise a jour privilège')"
								size="sm" 
								variant="flat-warning" 
								class="btn-icon"
                @click="setItem(data.item)"
							>
								<feather-icon icon="SettingsIcon" />
							</b-button>

							<b-button 
								size="sm" 
								variant="flat-danger" 
								class="btn-icon" 
								:disabled="!isDeletable(data.item.libelle)"
								@click="showMsgBoxOne(data.item.id)"
								v-if="$hasFonction(user, 'Mise a jour privilège')"
							>
								<feather-icon icon="TrashIcon"/>
							</b-button>
						</template>
						<!-- iconAction end -->

						<template #empty>
							<div class="text-center">
								<span class="text-nowrap">Aucune donnée existante</span>
							</div>
						</template>
						<template #emptyfiltered>
							<div class="text-center">
								<span class="text-nowrap">L'élément recherché n'est pas dans la liste</span>
							</div>
						</template>
					</b-table>
					<!-- table end -->

					<div class="my-2 mx-2 row">
						<!-- per-page end -->
						<div class="col">
							<b-form-select
								id="per-page-select"
								v-model="perPage"
								:options="pageOptions"
								style="width:70px"
							></b-form-select>
							<span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
								<span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
							</span>
							<span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
						</div>
						<!-- per-page end -->

						<!-- pagination start -->
						<b-pagination
							class="pagination" 
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							first-number
							last-number
							align="right"
							prev-class="prev-item"
							next-class="next-item"
							aria-controls="my-table"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
						<!-- pagination end -->
					</div>
				</b-card>

				<!-- modal-modification-utilisateur -->
				<b-modal
					id="modal-modification-utilisateur"
					v-model="model_modification_utilisateur"
					title="Modifier un groupe utilisateur"
					@hidden="resetModalEdit"
					centered
					hide-footer
				>
					<b-form ref="validationEditGroupeUtilisateur">
						<b-form-group>
							<validation-provider
								#default="{ errors }"
								name="libelle"
								rules="required"
							>
								<label for="libelle">Libelle</label>
								<b-form-input
									id="libelle"
									v-model="modifiedGroup.libelle"
									placeholder="Libellé" 
									@click="resetInputLibelleModalEdit"
									:state="errors.length > 0 ? false:null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
								<small class="text-danger">{{ errors[0]?'':errorsLibelleModalEdit }}</small>
							</validation-provider>
						</b-form-group>

						<b-form-group class="mb-0">
							<b-button
								variant="outline-secondary"
								type="reset"
								class="mt-2 mr-1"
								@click="model_modification_utilisateur=false"
							>
								Annuler
							</b-button>

							<b-button
								v-if="etatButtonEdit"
								variant="primary"
								class="mt-2"
								@click="updateGroup"
							>
								Enregistrer
							</b-button>

							<b-button 
								v-else variant="primary"
								class="mt-2"
							>
								<b-icon-arrow-clockwise
									animation="spin"
									font-scale="1"
								></b-icon-arrow-clockwise>
								En cours
							</b-button>
						</b-form-group>
					</b-form>
				</b-modal>


			</div>
			
			<div :hidden="hiddenEmpty" class="text-center">
				<empty-list></empty-list>
				<b-button
					v-b-modal.modal-ajouter-groupe-utilisateur
					variant="primary"
					size="sm"
				>
					<b-icon-plus></b-icon-plus>
					AJOUTER
				</b-button>
			</div>

			<!-- modal-ajouter-groupe-utilisateur -->
			<b-modal
				id="modal-ajouter-groupe-utilisateur"
				title="Ajouter groupe utilisateur"
				v-model="modalGrpU"
				@hidden="resetModalAdd"
				hide-footer
				centered
			>
				<b-form ref="validationAjoutGroupeUtilisateur">
					<b-form-group>
						<validation-provider
							#default="{ errors }"
							name="libelle"
							rules="required"
						>
							<label for="libelle">Libelle</label>
							<b-form-input
								id="libelle"
								v-model="libelle"
								placeholder="Libellé" 
								@click="resetInputLibelleModalAdd"
								:state="errors.length > 0 ? false:null"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
							<small class="text-danger">{{ errors[0]?'':errorsLibelleModalAdd }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group class="mb-0">
						<b-button
							variant="outline-secondary"
							type="reset"
							class="mt-2 mr-1"
							@click="modalGrpU=false"
						>
							Annuler
						</b-button>

						<b-button
							v-if="etatButton"
							variant="primary"
							class="mt-2"
							@click="handleOk"
						>
							Enregistrer
						</b-button>

						<b-button 
							v-else variant="primary"
							class="mt-2"
						>
							<b-icon-arrow-clockwise
								animation="spin"
								font-scale="1"
							></b-icon-arrow-clockwise>
							En cours
						</b-button>
					</b-form-group>
				</b-form>
			</b-modal>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import EmptyList from '@core/components/empty-list/EmptyList.vue'
import {
	BIconCircleFill,
	BIconArrowClockwise,
	BIconPlus,
	BIconFileEarmarkArrowDownFill,
	BCard,
 	BButton,
	BModal,
	BForm,
	BFormInput,
	BFormGroup,
	BPagination,
	BBadge,
	BFormSelect,
	BFormCheckbox,
	BRow,
	BCol,
	BContainer,
	BTable,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, alpha, alphaDash, } from '@validations'
import { json2excel, excel2json } from 'js2excel'
import downloadExcel from 'json2excel-js'
import store from '@/store/index'


export default {
  components: {
	BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BFormCheckbox,
	EmptyList,
	BIconPlus,
    BRow,
    BCol,
    BBadge,
    BContainer,
    vSelect,
    BTable,
	BIconCircleFill,
	BIconArrowClockwise,
	BIconFileEarmarkArrowDownFill,
	ValidationProvider,
    ValidationObserver,
	json2excel,
	excel2json,
	downloadExcel,
  },
  data() {
    return {
		etatButton: true,
		etatButtonEdit: true,
		modalGrpU: false,
		hidden: true,
		hiddenEmpty: true,
		hiddenLoader: false,
		perPage: 10,
		currentPage: 1,
		allRows: 0,
		pageOptions: [],
		dir: false,
		nameState: null,
		headers: [
		{
			key: 'libelle',
			label: 'Libelle',
			sortable: true,
		},
		{
			key: 'statut',
			label: 'Statut',
			sortable: true,
		},
		{
			key: 'etat',
			label: 'Etat',
			sortable: true,
		},
		{
			key: 'actions',
			label: 'Actions',
		},
		],
		groupeUtilisateur: [],
		ExcelGroupeUtilisateur: null,
		filter: '',
		bodyBgVariant: 'light',
		bodyTextVariant: 'dark',
		footerBgVariant: 'warning',
		footerTextVariant: 'dark',
		headerBgVariant: 'info',
		headerTextVariant: 'primary',
		model_modification_utilisateur: false,
		variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
		seleted: 'Administrateur',
		option: ['Administrateur', 'Fournisseur', 'Chargeur'],
		libelleState: null,
		libelle: null,
		errorsLibelleModalAdd: null,
		errorsLibelleModalEdit: null,
		modifiedGroup: {},
		settingItemModules: [],
		selected: [true],
		selectedModule: '',
		selectedFonction: '',
		selectedModules: [],
		selectedFonctions: [],
		isOperating: false,
		groupesListe: [],
		settingItem: {},
    }
  },
  computed: {
	  	...mapState({
				user: state => state.user.auth,
		}),
		totalRows() {
			this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
			return this.allRows;
    	},
		statusVariant() {
			const statusColor = {
				/* eslint-disable key-spacing */
				1 : 'light-success',
				'1' : 'light-success',
				true : 'light-success',
				0 : 'light-warning',
				'0' : 'light-warning',
				false : 'light-warning',
				/* eslint-enable key-spacing */
			}
			return status => statusColor[status]
		},

		direction() {
			if (store.state.appConfig.isRTL) {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.dir = true
				return this.dir
			}
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.dir = false
			return this.dir
		},
  },
  created() {
    this.getGroups()
  },
  methods: {
	// etatAdd(){
	// 	this.add = true
	// 	this.empty = false
	// },
	// reset pour ajouter un groupe start
	resetModalAdd() {
		this.libelle = ''
		this.resetInputLibelleModalAdd()
	},
	resetInputLibelleModalAdd(){
		if (this.errorsLibelleModalAdd) {
			this.errorsLibelleModalAdd = null
		}
	},

	onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
      },

	// reset pour ajouter un groupe end

	// reset pour modification d'un groupe start
	resetModalEdit() {
		this.modifiedGroup.libelle = ''
		this.resetInputLibelleModalEdit()
	},
	resetInputLibelleModalEdit(){
		if (this.errorsLibelleModalEdit) {
			this.errorsLibelleModalEdit = null
		}
	},
	// reset pour modification d'un groupe end

	showToast(titre, type) {
		this.$toast({
		component: ToastificationContent,
		props: {
			title: titre,
			icon: type === 'success' ? 'CheckSquareIcon' : 'AlertCircleIcon',
			variant: type,
		},
		})
	},
	checkFormValidity() {
		const valid = this.$refs.validationAjoutGroupeUtilisateur.checkValidity()
		this.nameState = valid
		return valid
	},
	checkEditFormValidity() {
		const valid = this.$refs.validationEditGroupeUtilisateur.checkValidity()
		this.nameState = valid
		return valid
	},
	handleSubmit() {
		// Exit when the form isn't valid
		if (!this.checkFormValidity()) {
			return
		}

		if(this.libelle){
			this.etatButton = false

			this.$http.post('user-groupes', {
				libelle: this.libelle,
			}).then(result => {
				if (result.data.success) {
					this.etatButton = true
					this.showToast('Ajout effectué', 'success')
					this.groupeUtilisateur.push(result.data.data)
					this.$bvModal.hide('modal-ajouter-groupe-utilisateur')
					this.getGroups()
				} else {
					this.etatButton = true
					this.showToast(result.data.message, "danger");
				}
			})
			.catch((e) => {
				this.etatButton = true
				console.log('erreur modification:' + e)
				// this.isOperating = false;
				var err = e.response.data.data
				// this.isOperating = false;

				if (err.libelle) {

					this.errorsLibelleModalAdd = err.libelle[0]
				}
			});
		} else{
			if (!this.libelle) {
				this.errorsLibelleModalAdd = "Ce champ est requis !"
			} else {
				this.errorsLibelleModalAdd = ""
			}
		}

		// Hide the modal manually
		this.$nextTick(() => {
			this.$bvModal.hide('modal-prevent-closing')
		})
	},
	handleOk(bvModalEvt) {
		// Prevent modal from closing
		bvModalEvt.preventDefault()
		// Trigger submit handler
		this.handleSubmit()
	},
	checkModule(mod) {
		let nbrChecked = 0
		// // check value of module 'show
			mod.parameter_fonctionnalities.forEach(element => {
		if (element.parameters.show) {
			nbrChecked++
		}
		})

		if (nbrChecked) {
			mod.checkShow = true
		} else {
			mod.checkShow = false
		}

		return mod
	},
	showMsgBoxOne(deletedItem) {
		this.$bvModal
		.msgBoxConfirm('Voulez vous vraiment supprimer ce groupe?', {
			title: 'Supprimer un groupe',
			okVariant: 'danger',
			cancelVariant: 'outline-secondary',
			okTitle: 'Confirmer',
			cancelTitle: 'Annuler',
			hideHeaderClose: false,
			centered: true,
		})
		.then(value => {
			if (value === true) {
			const index = this.groupeUtilisateur.indexOf(deletedItem)
			this.$http
				.delete(`/user-groupes/${deletedItem}`)
				.then(result => {
					if (result.data.success) {
						// console.log(result.data.data);
						this.showToast("Suppression effectuée avec succès", "success");
						this.getGroups()
					}  else {
						this.showToast(result.data.message, "danger");
					}
				})
				.catch(err => {
					this.showToast(err.response.data.message, 'danger')
				})
			}
		})
	},
	isDeletable(account) {
		const table = ['Chargeur', 'Fournisseur', 'Importateur', 'Administrateur']
		if (table.includes(account)) {
		return false
		}
		return true
	},
	updateGroup(bvModalEvt) {
		// Prevent modal from closing
		bvModalEvt.preventDefault()

		// Exit when the form isn't valid
		if (!this.checkEditFormValidity()) {
			return
		}

		if(this.libelle){
			this.etatButtonEdit = false

			this.$http
			.put(`/user-groupes/${this.modifiedGroup.id}`, this.modifiedGroup)
			.then(result => {
				this.isOperating = false
				if (result.data.success) {
					this.etatButtonEdit = true
					this.modifiedGroup = JSON.parse(
						JSON.stringify({ type: Object, default: () => {} })
					);
					this.model_modification_utilisateur = false
					this.showToast('Groupe modifié avec succès', 'success')
					this.getGroups()
				} else {
					this.etatButtonEdit = true
					this.showToast(result.data.message, "danger");
				}
			})
			.catch((e) => {
				this.etatButtonEdit = true
				console.log('erreur modification:' + e)
				// this.isOperating = false;
				var err = e.response.data.errors
				// this.isOperating = false;

				if (err.libelle) {

					this.errorsLibelleModalEdit = err.libelle[0]
				}
			});
		} else{
			if (!this.libelle) {
				this.errorsLibelleModalAdd = "Ce champ est requis !"
			} else {
				this.errorsLibelleModalAdd = ""
			}
		}

		// Hide the modal manually
		this.$nextTick(() => {
			this.$bvModal.hide('modal-prevent-closing')
		})
	},
	editItem(item) {
		// this.editedIndex = this.groups.indexOf(item)
		// this.modifiedGroup = item
		// this.modifiedGroup = Object.assign({}, item)
		this.modifiedGroup = { ...item, ...{} }
		this.model_modification_utilisateur = true
	},
	checkSelectedModule() {
		const check = this.selectedModules.find(
		({ id }) => id === this.selectedModule,
		)
		if (check) {
		this.selectedFonctions = check.fonctionnalities
		}
	},
	setItem(item) {
		this.getModules(item)
		window.sessionStorage.setItem('id', item.id)
		this.$router.push(
			{ 
				name: 'groups-modules', 
				params: { id: item.id } 
			}
		)
	},
	getModules(item) {
		this.$http.get(`/user-groupes/${item.id}`).then(result => {
		this.settingItemModules = []
		this.settingItem = []
		this.selectedFonctions = []
		this.selectedModules = []
		this.settingItem = { ...result.data.data, ...{} }
		const resul = Object.entries(this.settingItem.modules)
		resul.forEach(element => {
			const mod = this.checkModule(element[1])
			this.settingItemModules.push(mod)
		})
		})
	},
	getGroups() {
		this.$http.get('user-groupes')
		.then(res => {
			
			if (res.status) {
				this.hiddenLoader = true
			}

			this.groupeUtilisateur = res.data.data.reverse()

			if (this.groupeUtilisateur.length) {
				this.hidden = false
				this.hiddenEmpty = true
			} else {
				this.hidden = true
				this.hiddenEmpty = false
			}

			this.allRows = this.groupeUtilisateur.length

		})
		.catch(e => {

		})
	},

	checkSelectedGroup() {
		let data = this.groupesListe.find(
		({ libelle }) => libelle == this.formItem.role
		)

		if (data) {
		this.selectedAccountType = data.libelle

		let table = ['Chargeur', 'Fournisseur', 'Importateur']

		if (table.includes(this.selectedAccountType)) {
			this.hasResources = true
			this.getDatas(this.selectedAccountType)
		} else {
			this.hasResources = false
		}
		}
	},  

	setState(item) {
		this.isOperating = true
		this.$http
			.put('/commuter-groupe/' + item.id, item)
			.then((result) => {
			this.isOperating = false
			if (!result.data.success) {
				item.show = !item.show
				this.showToast('Mise à jour non effectuée', 'error')
			} else this.showToast('Mise à jour effectuée', 'success')
			})
			.catch(() => {
			this.isOperating = false
			this.showToast('Mise à jour non effectuée', 'error')
			})
	},

	dialogstatut(item) {
		//this.userSet = item
		this.$bvModal
		.msgBoxConfirm("Vous êtes sur le point de changer d'état au groupe d'utilisateur " +
			item.libelle + '. Voulez-vous continuer ?', {
			title: 'Changer l\'Etat',
			okVariant: 'primary',
			cancelVariant: 'outline-secondary',
			okTitle: 'Confirmer',
			cancelTitle: 'Annuler',
			hideHeaderClose: false,
			centered: true,
		})
		.then(value => {
			if(value === true){
				this.setState(item)
			}else{
				if(item.show ===  0 || item.show ===  '0'){
					item.show = 1
				}else{
					item.show = 0
				}
				item = {}
			}
		})
	},

	checkFunction(fonction) {
		let mod = this.settingItemModules.find(
		({ id }) => id === fonction.parameters.module_id
		)

		let check = mod.parameter_fonctionnalities.find(
		({ show }) => show == false
		)
		if (!fonction.show) {
		if (check) {
			// this.settingItemModules.find(
			//   ({ id }) => id === fonction.parameters.module_id
			// ).show = false
		}
		} else {
		this.settingItemModules.find(
			({ id }) => id === fonction.parameters.module_id
		).show = fonction.show
		}

		fonction.showFunction = fonction.parameters.show
		this.groupItem = this.getModuleToUpdate('fonction', [fonction], mod)
		this.switchFonctionnality(fonction.parameters.module_id)

		// console.log(this.groupItem)
	},

	getModuleToUpdate(toUpdate = null, fonctions = [], mod) {
		let obj = {
		module: {
			id: mod.id,
			value: mod.checkShow,
		},
		toUpdate: toUpdate,
		fonctions: [],
		}
		fonctions.forEach((element) => {
		// console.log(element.showFunction)
		obj.fonctions.push({
			id: element.id,
			value: element.showFunction ? true : false,
		})
		})
		return obj
	},

	switchFonctionnality(module_id) {
		this.isOperating = true
		this.$http
		.put('/commuter-fonction/' + this.settingItem.id, this.groupItem)
		.then((result) => {
			this.isOperating = false

			this.refreshModules()

			this.showToast('Mise à jour effectuée', 'success')
		})
		.catch(() => {
			this.isOperating = false

			this.isSuccess = false
			this.showToast('Mise à jour non effectuée', 'error')
		})
	},
	refreshModules() {
		this.getModules(this.settingItem)
	},
	setModule(mod) {
		let showModule = mod.checkShow ? true : false
		// check value of module 'show

		// console.log(showModule)
		mod.parameter_fonctionnalities.forEach((element) => {
		element.showFunction = showModule
		// if (element.parameters.show != showModule) {
		//   element.show = showModule
		// }
		})
		this.groupItem = this.getModuleToUpdate(
		'module',
		mod.parameter_fonctionnalities,
		mod
		)
		this.switchFonctionnality(mod.id)
	},

	ExportFomatExcel () {
		let { json2excel, excel2json } = require('js2excel')
		// let headers = this.headers
		let data = []
		let obj = {}
		
		this.ExcelGroupeUtilisateur.forEach(element => {
			obj = {
				'LIBELLE': element.libelle,
				'STATUT': element.show ? 'actif' : 'inactif',
			}

			data.push(obj)
		});

		console.log('data')
		
		if (data) {
			try {
				json2excel({
					// headers,
					data,
					name: 'FlashCar - Liste groupes users',
					formateDate: 'yyyy/mm/dd',
				})
			} catch (e) {
				console.log(e)
			}
		}
	},

	ExportAllToFomatExcel () {
		let { json2excel, excel2json } = require('js2excel')
		// let headers = this.headers
		let data = []
		let obj = {}
		
		this.groupeUtilisateur.forEach(element => {
			obj = {
				'LIBELLE': element.libelle,
				'STATUT': element.show ? 'actif' : 'inactif',
			}

			data.push(obj)
		});

		console.log('data')
		
		if (data) {
			try {
				json2excel({
					// headers,
					data,
					name: 'FlashCar - Liste groupes users',
					formateDate: 'yyyy/mm/dd',
				})
			} catch (e) {
				console.log(e)
			}
		}
	},

  },
}
</script>

<style lang="scss" >
	#loader {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #f8f8f8;
		padding-top: 15%;
		z-index: 1000;
	}

	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-good-table.scss';
	@import '@core/scss/vue/pages/ui-feather.scss';
</style>
